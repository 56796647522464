window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');

    require('air-datepicker-en');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


window.$(document).ready(function() {
  window.$('.carousel').carousel();

  // window.$.fn.datepicker.language['ru'] =  {
  //   days: ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
  //   daysShort: ['Вос','Пон','Вто','Сре','Чет','Пят','Суб'],
  //   daysMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
  //   months: ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
  //   monthsShort: ['Янв','Фев','Мар','Апр','Май','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'],
  //   today: 'Сегодня',
  //   clear: 'Очистить',
  //   // dateFormat: 'dd.mm.yyyy',
  //   timeFormat: 'hh:ii',
  //   firstDay: 1
  // };

  window.$('.book-datepicker').datepicker({
    language: 'en',
    minDate: new Date(),
    dateFormat: 'd.mm.yyyy',
    timepicker: false,
    // minutesStep: 15

  });
});
